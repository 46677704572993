<template>
  <div class="customerlist">
    <div class="top">
      <div class="querybox">
        <div>
          <p>手机号/登陆账号:</p>
          <el-input
            v-model="queryParams.phone"
            placeholder="请输入手机号/登陆账号"
            clearable
          ></el-input>
        </div>
        <div>
          <p>昵称:</p>
          <el-input
            v-model="queryParams.nickname"
            placeholder="请输入昵称"
            clearable
          ></el-input>
        </div>
        <div>
          <p>时间筛选</p>
          <el-date-picker
            v-model="value"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            @change="timeChange"
          >
          </el-date-picker>
        </div>
      </div>
      <div class="handlebox">
        <div class="btnbox">
          <el-button
            type="primary"
            icon="el-icon-search"
            class="blue-btn"
            @click="search"
            >查询</el-button
          >
          <el-button
            type="primary"
            icon="el-icon-refresh-right"
            class="gray-btn"
            style="color: #1d2129"
            @click="resetFn"
            >重置</el-button
          >
        </div>
      </div>
    </div>
    <div class="content">
      <el-table
        :data="tableData"
        tooltip-effect="dark"
        :header-cell-style="{ 'text-align': 'center' }"
        :cell-style="{ 'text-align': 'center' }"
        show-overflow-tooltip
      >
        <!-- <el-table-column
          type="index"
          width="100"
          label="编号"
        ></el-table-column> -->
        <el-table-column prop="headImage" label="头像">
          <template slot-scope="{ row }">
            <div class="logo">
              <img :src="row.headImage" alt="" />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="phone" label="账户"></el-table-column>
        <el-table-column prop="nickname" label="昵称"></el-table-column>
        <el-table-column prop="sex" label="性别">
          <template slot-scope="{ row }">
            <span :style="{ color: row.sex === 0 ? '#FF1493' : '#4169E1' }">{{
              row.sex === 0 ? "女" : "男"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="birthday" label="生日">
          <template slot-scope="{ row }">
            {{
              row.birthday ? $moment(row.birthday).format("YYYY-MM-DD") : "未知"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="lastLogin" label="最近登录时间" min-width="120">
        </el-table-column>
        <el-table-column prop="lastLogin" label="公众号">
          <template slot-scope="{ row }">
            <span>{{ row.isSubscribe === 0 ? "未关注" : "已关注" }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="createdTime" label="注册时间" min-width="120">
        </el-table-column>
        <el-table-column
          prop="raffleActivityBindParentName"
          label="抽奖活动邀请人"
          min-width="100"
        >
          <template slot-scope="{ row }">
            <el-button
              type="text"
              class="textblue-btn"
              @click="checkItem(row.id)"
              >{{ row.raffleActivityBindParentName }}</el-button
            >
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态">
          <template slot-scope="{ row }">
            <span
              :style="{ color: row.status === 0 ? '#5E83F5' : '#EE3A3A' }"
              >{{ row.status === 0 ? "启用" : "禁用" }}</span
            >
          </template>
        </el-table-column>
        <el-table-column label="操作" min-width="120">
          <template slot-scope="scope">
            <el-popconfirm
              title="是否启用该用户？"
              popper-class="customerdel-popover"
              @confirm="cancelOrder(scope.row, 0)"
              v-if="Number(scope.row.status) === 1"
            >
              <el-button class="blue-btn" slot="reference">启用</el-button>
            </el-popconfirm>
            <el-popconfirm
              title="是否禁用该用户"
              popper-class="customerdel-popover"
              @confirm="cancelOrder(scope.row, 1)"
              v-if="Number(scope.row.status) === 0"
            >
              <el-button class="red-btn" slot="reference">禁用</el-button>
            </el-popconfirm>
            <el-button class="textred-btn" @click="logoutUser(scope.row.id)"
              >注销</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页组件 -->
      <pagination
        v-show="total > queryParams.pageSize"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="init"
      />
    </div>
    <!-- 抽奖活动邀请人弹窗 -->
    <el-dialog
      title="抽奖活动上级绑定记录"
      :visible.sync="outerVisible"
      width="65%"
      :before-close="handleClose"
      :show-close="false"
    >
      <div slot="title" class="title">
        <p>抽奖活动上级绑定记录</p>
        <span @click="handleClose"><i class="el-icon-close"></i></span>
      </div>
      <el-dialog
        width="40%"
        :visible.sync="innerVisible"
        append-to-body
        :show-close="false"
      >
        <div slot="title" class="title">
          <p>变更绑定</p>
          <span @click="handleInnerClose"><i class="el-icon-close"></i></span>
        </div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          class="demo-ruleForm"
          hide-required-asterisk
        >
          <el-form-item label="抽奖活动邀请人电话：" prop="phone">
            <el-input
              v-model="ruleForm.phone"
              placeholder="抽奖活动邀请人电话为0,归属平台所有"
            ></el-input>
          </el-form-item>
          <el-form-item label="备注：" prop="notes">
            <el-input
              type="textarea"
              v-model="ruleForm.notes"
              placeholder="请输入变更备注"
            ></el-input>
          </el-form-item>
          <el-form-item style="text-align: right">
            <el-button @click="resetForm('ruleForm')" class="gray-btn"
              >重置</el-button
            >
            <el-button
              type="primary"
              class="blue-btn"
              @click="submitForm('ruleForm')"
              >确定</el-button
            >
          </el-form-item>
        </el-form>
      </el-dialog>
      <div class="bind-content">
        <div class="bind-btn">
          <el-button class="blue-btn" @click="editBind"> 变更绑定</el-button>
        </div>
        <el-table
          :data="bindList"
          tooltip-effect="dark"
          :header-cell-style="{ 'text-align': 'center' }"
          :cell-style="{ 'text-align': 'center' }"
          show-overflow-tooltip
        >
          <el-table-column
            prop="parentName"
            label="抽奖活动邀请人"
            min-width="150"
          ></el-table-column>
          <el-table-column
            prop="parentPhone"
            label="抽奖活动邀请人账户"
            min-width="180"
          >
            <template slot-scope="{ row }">
              {{ row.parentPhone ? row.parentPhone : "-" }}
            </template>
          </el-table-column>
          <el-table-column prop="createdTime" label="来源" min-width="150">
            <template slot-scope="{ row }">
              <span
                :style="{ color: row.source === 0 ? '#5E83F5' : '#EE3A3A' }"
                >{{ row.source_dictText }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="parameterString"
            label="活动名称/操作人"
            min-width="200"
          >
          </el-table-column>
          <el-table-column prop="createdTime" label="操作时间" min-width="200">
          </el-table-column>
          <el-table-column
            prop="notes"
            label="备注"
            min-width="100"
            show-overflow-tooltip
          >
          </el-table-column>
        </el-table>
        <!-- 分页组件 -->
        <pagination
          v-show="bindtotal > bindParams.pageSize"
          :total="bindtotal"
          :page.sync="bindParams.pageNum"
          :limit.sync="bindParams.pageSize"
          @pagination="initBind"
        />
      </div>
      <!-- <div slot="footer" class="dialog-footer">
        <el-button @click="outerVisible = false">取 消</el-button>
        <el-button type="primary" @click="innerVisible = true"
          >打开内层 Dialog</el-button
        >
      </div> -->
    </el-dialog>
    <!-- 注销弹窗 -->
    <el-dialog
      :visible.sync="logoutVisible"
      width="35%"
      :before-close="logoutClose"
      :show-close="false"
    >
      <div slot="title" class="title">
        <p>注销账户</p>
        <span @click="logoutClose"><i class="el-icon-close"></i></span>
      </div>
      <div class="write-box">
        <img src="@/assets/error.png" alt="" />
        <div class="tips">
          账户注销将永久失效且不可恢复，注销后你账号中的个人资料,课程资料等内容将无法找回,请妥善处理
        </div>
        <div class="reason-box">
          <div
            v-for="(item, index) in reasonList"
            :key="index"
            @click="checkReason(index)"
          >
            {{ index + 1 }}. {{ item }}
            <img
              v-if="index === logoutReason"
              src="@/assets/check.png"
              alt=""
            />
          </div>
        </div>
        <div class="write-btn" @click="sumbitWrite">确定注销</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getUserList,
  editUserStatus,
  getBindList,
  editBindInfo,
  setLogout,
} from "@/api/login";
export default {
  data() {
    return {
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        phone: "",
        nickname: "",
        startTime: "",
        endTime: "",
      },
      bindParams: {
        pageNum: 1,
        pageSize: 10,
        userId: "",
      },
      bindtotal: 0,
      bindList: [],
      tableData: [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      value: "",
      total: 0, //总数
      outerVisible: false,
      innerVisible: false,
      ruleForm: {
        notes: "",
        phone: "",
      },
      rules: {
        notes: [{ required: true, message: "请输入变更备注", trigger: "blur" }],
        phone: [{ required: true, message: "请输入号码", trigger: "blur" }],
      },
      userId: null,
      logoutVisible: false,
      reasonList: ["个人信息担忧", "对平台失望", "个人原因", "账户被盗用"],
      logoutReason: 0,
    };
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    //获取用户列表
    async init() {
      const res = await getUserList(this.queryParams);
      this.tableData = res.result.records;
      this.total = res.result.total;
    },
    async initBind() {
      const res = await getBindList(this.bindParams);
      this.bindList = res.result.records;
      this.bindtotal = res.result.total;
    },
    checkItem(id) {
      this.bindParams.userId = id;
      this.initBind();
      this.outerVisible = true;
    },
    editBind() {
      this.innerVisible = true;
    },
    timeChange(event) {
      // console.log(event);
      if (event) {
        this.queryParams.startTime = this.$moment(event[0])
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.queryParams.endTime = this.$moment(event[1])
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
      } else {
        this.queryParams.startTime = "";
        this.queryParams.endTime = "";
      }
      console.log(this.queryParams);
    },
    // 查询
    search() {
      this.queryParams.pageNum = 1;
      this.init();
    },
    //重置
    resetFn() {
      this.queryParams.phone = "";
      this.queryParams.nickname = "";
      this.queryParams.pageNum = 1;
      this.queryParams.startTime = "";
      this.queryParams.endTime = "";
      this.value = "";
      this.init();
    },
    // 启用禁用
    async cancelOrder(item, status) {
      const params = {
        status,
        id: item.id,
      };
      await editUserStatus(params);
      // this.$refs[`${text}-popover-${index}`].doClose();
      this.init();
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          // alert("submit!");
          const params = {
            ...this.ruleForm,
            userId: this.bindParams.userId,
          };
          await editBindInfo(params);
          this.initBind();
          this.$message.success("操作成功");
          this.innerVisible = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleClose() {
      this.outerVisible = false;
    },
    handleInnerClose() {
      this.innerVisible = false;
    },
    // 注销用户弹窗
    logoutUser(id) {
      this.userId = id;
      this.logoutVisible = true;
    },
    logoutClose() {
      this.logoutVisible = false;
    },
    checkReason(index) {
      this.logoutReason = index;
    },
    async sumbitWrite() {
      const params = {
        id: this.userId,
        logoutReason: this.reasonList[this.logoutReason],
      };
      await setLogout(params);
      this.logoutVisible = false;
      this.$message.success("账户注销成功");
      this.init();
    },
  },
};
</script>
<style lang="scss" scoped>
.customerlist {
  .top {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #0000000d;
    padding-bottom: 10px;
    ::v-deep .querybox {
      display: flex;
      align-items: center;

      > div {
        display: flex;
        align-items: center;
        margin-right: 22px;
        p {
          color: #4e5969;
          line-height: 22px;
          margin-right: 16px;
          white-space: nowrap;
        }
        .el-input,
        .el-range-editor {
          width: 256px;
        }
        input::-webkit-input-placeholder {
          /* WebKit browsers */
          color: #86909c;
        }
        .el-input__inner,
        .el-range-input {
          background: #f7f8fa;
          border: none;
          color: #86909c;
        }
      }
      .btnbox {
        display: flex;
        .el-button {
          padding: 10px 0;
          width: 84px;
          box-sizing: border-box;
          // margin-left: 22px;
        }
      }
    }
    .handlebox {
      display: flex;
      align-items: center;
      .el-button {
        padding: 10px 0;
        width: 105px;
        box-sizing: border-box;
        // margin-left: 22px;
      }
    }
  }
  .content {
    padding-top: 20px;
    .logo {
      display: flex;
      justify-content: center;
      img {
        width: 38px;
        height: 38px;
        border-radius: 100%;
      }
    }
    .el-button {
      padding: 5px 0;
      width: 80px;
      box-sizing: border-box;
    }
  }
  ::v-deep(.el-dialog) {
    .el-dialog__body {
      display: flex;
      align-items: center;
      justify-content: center;
      .imgBox {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        width: 100%;
        img {
          width: 40%;
          height: auto;
        }
      }
      .OutBtnBox {
        margin-top: 10px;
        text-align: center;
      }
    }
  }
  .textred-btn,
  .textblue-btn {
    padding: 0 5px;
  }
}
.bind-content {
  min-height: 200px;
  .bind-btn {
    text-align: right;
    margin-bottom: 20px;
  }
}
.bind-title {
  font-size: 16px;
  color: #333333;
  line-height: 22px;
}
::v-deep .demo-ruleForm {
  padding-bottom: 40px;
  .el-form-item {
    // display: flex;
    .el-button {
      padding: 12px 0;
      width: 160px;
      margin-top: 26px;
      font-size: 18px;
    }
    align-items: center;
    margin-bottom: 40px;
    .el-form-item__label {
      // min-width: 150px;
      white-space: nowrap;
      font-size: 16px;
    }
    .el-form-item__content {
      font-size: 16px;
      .el-textarea__inner {
        font-size: 16px;
        color: #86909c;
        background: #f7f8fa;
      }
      .el-input {
        width: 300px;
        font-size: 16px;
        .el-input__inner {
          background: #f7f8fa;
          border: none;
          color: #86909c;
          line-height: 22px;
          padding: 5px 12px;
        }
      }
      input::-webkit-input-placeholder {
        /* WebKit browsers */
        color: #86909c;
      }
    }
    .el-form-item__error {
      padding-top: 5px;
    }
  }
}
.write-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 16px;
}

.write-box > img {
  width: 124px;
  height: 98px;
}

.write-box .tips {
  font-weight: bold;
  font-size: 24px;
  color: #161616;
  line-height: 40px;
  margin: 16px 0 32px;
  text-align: center;
}

.reason-box {
  background: #d3d3d3;
  border-radius: 12px;
  padding: 16px;
  box-sizing: border-box;
  width: 100%;
}

.reason-box > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  color: #0c0c0c;
  line-height: 40px;
  margin-bottom: 32px;
  cursor: pointer;
}

.reason-box > div:last-child {
  margin-bottom: 0;
}

.reason-box > div img {
  width: 30px;
  height: 30px;
}
.write-btn {
  background: #6c24ec;
  border-radius: 12px;
  padding: 20px 0;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
  line-height: 1;
  width: 50%;
  margin-top: 32px;
  cursor: pointer;
}
</style>
